import './Edu.css';
import { React, useEffect} from 'react';
import { Container, Row, Col, Image, Card, Button } from 'react-bootstrap';
import { BsLinkedin, BsGithub } from 'react-icons/bs';
import { HiOutlineMailOpen, HiOutlineMenuAlt3 } from "react-icons/hi";
import { IoArrowUndoOutline } from "react-icons/io5";

function Edu() {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const myData = require('./assets/data.json');

  function handleBackBtn() {
    console.log("Back Button clicked")
    window.history.back()
  }

  return (
    <Container className='e-top-div'>
      <Container className='e-social-info' fluid>
        <Container className='e-icon-wrapper'>
          <a href={myData.info.linkedin} className='e-social-icon'>
            <BsLinkedin size={'1.5em'}/>
          </a>
        </Container>
        <Container className='e-icon-wrapper'>
          <a href={myData.info.github} className='e-social-icon'>
            <BsGithub size={'1.5em'}/>
          </a>
        </Container>
        <Container className='e-icon-wrapper'>
          <a href={`mailto:${myData.info.email}`} className='e-social-icon'>
            <HiOutlineMailOpen size={'1.5em'}/>
          </a>
        </Container>
      </Container>
      <Container className='e-Landing' fluid >
        <Container className='e-landing-name'>
          <Button className='back-btn' variant='outline-dark' onClick={() => handleBackBtn()}>
            <IoArrowUndoOutline /> Back
          </Button>
          <h2 className='education-title'>Coursework</h2>
        </Container>
      </Container>
      <Container className='edu-content'>
        <h3 className='education-text'>at the {myData.info.education.school_name}.</h3>
        {myData.coursework.map((p) =>
          <Container className='course-period'>
            <h4 className='period-text'>{p.period}</h4>
            {p.courses.map((c) =>
              <Container className='course'>
                <h5 className='course-title'>{c.id}: {c.name}</h5>
                <a href={c.website}>
                  with {c.professor} 
                </a>
                <h6>{c.description}</h6>
              </Container>          
            )}
          </Container>          
        )}
      </Container>
      <Container className='footer'>
        {/* <h1>Hello</h1> */}
        < Container className='copyright'>
          <h7 className='copyright-text1'>Developed and Designed by Thomas Ian Budiman</h7>
          <h7 className='copyright-text2'>All rights reserved © </h7>
        </Container>
      </Container>
    </Container>
  )
}

export default Edu;