import './App.css';
import React from 'react';
import { Container, Image, Button } from 'react-bootstrap';
import downArrow from './assets/down-arrow-svgrepo-com.svg'
import { BsLinkedin, BsGithub } from 'react-icons/bs';
import { HiOutlineMailOpen } from "react-icons/hi";
import myResume from "./assets/Thomas_Budiman_Resume.docx"
import uttower1 from "./assets/uttower1.svg"
import { Link } from "react-router-dom";

function App() {
  const myData = require('./assets/data.json');
  const experiences = myData.experiences
  const projects = myData.projects

  function handleScrollDownBtn() {
    console.log("Scroll Down Button clicked")
    console.log(window.innerHeight)
    window.scroll({
      left: 0,
      top: window.innerHeight,
      behavior: 'smooth',
    });
  }

  return (
    <Container className='top-div' fluid>
      <Container className='social-info' fluid>
        <Container className='icon-wrapper'>
          <a href={myData.info.linkedin} className='social-icon'>
            <BsLinkedin size={'1.5em'}/>
          </a>
        </Container>
        <Container className='icon-wrapper'>
          <a href={myData.info.github} className='social-icon'>
            <BsGithub size={'1.5em'}/>
          </a>
        </Container>
        <Container className='icon-wrapper'>
          <a href={`mailto:${myData.info.email}`} className='social-icon'>
            <HiOutlineMailOpen size={'1.5em'}/>
          </a>
        </Container>
      </Container>
      <Container className='Landing' fluid >
        <Container className='landing-name'>
          <h2 className='hello-text'>Hello.</h2>
          <h4 className='name-text'>I'm {myData.info.name.first} {myData.info.name.middle} {myData.info.name.last}.</h4>
          <h7 className='my-desc'>Computer Science Graduate.</h7>
        </Container>
        <Container className='down-arrow-div'>
          <Image className='down-arrow' src={downArrow} onClick={() => handleScrollDownBtn()}></Image>
        </Container>
      </Container>
      <Container className='about-me'>
        <Container className='about-me-text'>
          <h1 className='about-me-title'>
            About Me
          </h1>
          <h5 className='about-me-desc'>
            {myData.info.education.school_name}, Class of 2022
            <Image src={uttower1} className='ut-tower'></Image>
          </h5>
          {/* <h6>
            <Link to="/education">More info</Link>
          </h6> */}
          <h7>
            {myData.info.about_me}
          </h7>
        </Container>
        <Container className='resume-btn-div'>
          <a href={"/education"}>
            <Button className='coursework-btn' variant='outline-dark'>
              Coursework
            </Button>
          </a>
          <a href={"https://drive.google.com/file/d/1cPzpssC0M5VccAqL-7fu1Yqng_rY1wKo/view?usp=sharing"} download>
            <Button className='resume-btn' variant='outline-dark'>
              Download Resume Here
            </Button>
          </a>
        </Container>
      </Container>
      <Container className='hr1'>
        <hr />
      </Container>
      <Container className='experience'>
        <h1 className='experience-title'>
          Experience
        </h1>
        {experiences.map((e) =>
          <Container className='experience-text'>
            <h4>{e.name}: {e.position}</h4>
            <h5>{e.start_month} {e.start_year} - {e.end_month} {e.end_year}</h5>
            <h5>{e.location}</h5>
            {
              e.description.map((l) =>
                <Container className='experience-desc'>
                  <h7>{l}</h7>
                </Container>
              )
            }
            {/* <h7>{e.description}</h7> */}
          </Container>          
        )}
      </Container>
      <Container className='hr1'>
        <hr />
      </Container>
      <Container className='experience'>
        <h1 className='experience-title'>
          Projects
        </h1>
        {projects.map((e) =>
          <Container className='experience-text'>
            <h4>{e.name}</h4>
            <h5>{e.position}</h5>
            <h5>{e.start_month} {e.start_year} - {e.end_month} {e.end_year}</h5>
            {
              e.description.map((l) =>
                <Container className='experience-desc'>
                  <h7>{l}</h7>
                </Container>
              )
            }
            {/* <h7>{e.description}</h7> */}
          </Container>          
        )}
      </Container>
      <Container className='footer'>
        {/* <h1>Hello</h1> */}
        < Container className='copyright'>
          <h7 className='copyright-text1'>Developed and Designed by Thomas Ian Budiman</h7>
          {/* <h7 className='copyright-text2'>All rights reserved © </h7> */}
        </Container>
      </Container>
    </Container>
  );
}

export default App;
